import React from "react";
import "./Footer2.css"; 

import {useHistory} from 'react-router-dom';
import Logo from "../CommonApps/Logo";
import { BsFacebook, BsLinkedin, BsTwitter, BsYoutube } from "react-icons/bs";


const Footer2 = () => {



    const history = useHistory();


   const homePageHandler=()=>{

    history.push('/');

  }


  const privacyPolicyHandler = () => {
    history.push("/Privacypolicy");
  };


 

  const helpCenterHandler = () => {
    history.push("/HelpCenter");
  };

  const cookiesPolicyHandler = () => {
    history.push("/CookiesPolicy");
  };


  const disclaimerHandler = () => {
    history.push("/TermsofUse");
  };

  const refundPolicyHandler = () => {
    history.push("/RefundPolicy");
  };

  const termsofuseHandler = () => {
    history.push("/TermsofUse");
  };


  const twitterHandler = () => {
    window.open("https://twitter.com/DiracAI1", "_blank");
  };

  const facebookHandler = () => {
    window.open("https://www.facebook.com/diracai", "_blank");
  };

  const youtubeHandler = () => {
    window.open("https://www.youtube.com/@DiracAIVlogs", "_blank");
  };

  const linkedInHandler = () => {
    window.open(
      "https://www.linkedin.com/company/diracai-services/?viewAsMember=true",
      "_blank"
    );
  };

  
  return (
    <div className="footer">

        <div className="footer-inner"> 
      <div className="footer-container">
        {/* Logo Section */}

        <div className="footer-logo-container"> 
        {/* <div className="footer-logo">
          <div className="logo-text-png"></div>
        </div> */}


<div className="logoCSS"  style={{color:"var(--themeColor)"}} onClick={homePageHandler}>	     
                  {/* <img src={logoImage} className={classes.logoImage}/>*/}
                        {/* <Logo />           */}
                  <span className="companyName"> DiracAI </span>
             </div>


        {/* Subscription Section */}
        <div className="footer-subscription">
          <div className="insights-text">
          <span className="span">   Our insights to your </span>
          {/* <br /> */}
           <span className="span"> inbox </span>  
          </div>
          <div className="subscription-form">
            <input
              type="email"
              placeholder="Your Email..."
              className="email-input"
            />
            <button className="send-button">Send</button>
          </div>
        </div>

        </div>

        {/* Social Media Section */}
        {/* <div className="social-media-icons">
          <div className="social-icon"></div>
          <div className="social-icon"></div>
          <div className="social-icon"></div>
        </div> */}

        {/* Contact Information Section */}


        <div className='contact-section-container'> 
        <div className="contact-section">
          <h6 className="contact-heading"> Our Company</h6>
          <p className="contact-item">About DiracAI</p>
          <p className="contact-item">Blog</p>
          <p className="contact-item">Career</p> 
        </div>

        <div className="contact-section">
          <h6 className="contact-heading">Our Products</h6>
          <p className="contact-item">DashoApp </p>
          <p className="contact-item">Learning Management System</p>
          <p className="contact-item">Institute Management System</p>
          <p className="contact-item">Exam Management System</p>
          <p className="contact-item">Student Assessment System ( AI )</p>
          <p className="contact-item">Digital Books</p>
          <p className="contact-item">Ticketing App</p>
          <p className="contact-item">Online Meeting App</p>

        </div>

        <div className="contact-section">
          <h6 className="contact-heading"> Our Services</h6>
          <p className="contact-item"   onClick={() => history.push("/it-services")}>Custom Web Development</p>
          <p className="contact-item"   onClick={() => history.push("/it-services")}>Mobile Development</p>
          <p className="contact-item"   onClick={() => history.push("/it-services")}>Data Analytics Solutions</p>
          <p className="contact-item"   onClick={() => history.push("/it-services")}>EdTech Platform Development</p>
          <p className="contact-item"   onClick={() => history.push("/it-services")}>Database Management</p>
          <p className="contact-item"   onClick={() => history.push("/it-services")}>
          Digital Marketing Services</p>
                 </div>

        <div className="contact-section2">
          <h6 className="contact-heading">Contact Us</h6>
          <div className="cont1Text">
              <div className="address">
                <div className="header">
                  <strong  > INDIA ( Bhubaneswar ) </strong>
                </div>
                <div className="l">HIG-306, K-5, Kalinga Vihar,</div>
                <div className="l">Bhubaneswar, Odisha, India</div>
                <div className="l">Pin : 751007</div>
                <div className="l">Phone : +91 7008639757</div>
                <div className="l">Email : contact@diracai.com</div>
              </div>
              <div className="address">
              <div className="header">
                  <strong  > INDIA ( Pune )</strong>
                </div>
                <div className="l">L 202, 2nd Floor, Sai Dreams Phase 2,</div>
                <div className="l">Kate Vasti Road, Pimple Saudagor,</div>
                <div className="l">Kunj Colony, Pimpri Chinchwad,</div>
                <div className="l">Maharashtra, 411027, India</div>
              </div>

              {/* <div className="address">
              <div className="header">
                  <strong  >FRANCE</strong>
                </div>
                <div>11, Presquile,</div>
                <div>Andre-Malraux</div>
                <div>Strasbourg,</div>
                <div>France, 67100</div>
              </div> */}
            </div>
        </div>

        </div>


        {/* Links Section */}
        {/* <div className="links-section">
          <ul className="footer-links">
            <li className="footer-link">CRM Travel</li>
            <li className="footer-link">Technology Partner</li>
            <li className="footer-link">Digital Transformation</li>
          </ul>
        </div> */}

        {/* Footer Bottom Section */}
      
      </div>

      <div className="cont4">
            <BsTwitter className="cont4Icon" onClick={twitterHandler} />
            <BsFacebook
              className="cont4Icon"
              onClick={facebookHandler}
            />
            <BsYoutube className="cont4Icon" onClick={youtubeHandler} />
            <BsLinkedin
              className="cont4Icon"
              onClick={linkedInHandler}
            />
          </div>

      <div className="footer-bottom">
          <p className="copyright">© 2024 DiracAI. All Rights Reserved.</p>
          <div className="footer-policies"> 
          <span className="policy-link"  onClick={refundPolicyHandler}>Refund Policy</span>



          <span className="policy-link"  onClick={termsofuseHandler}>Terms of Use</span>
          <span className="policy-link" onClick={privacyPolicyHandler}>Privacy Policy</span>
            <span className="policy-link" onClick={cookiesPolicyHandler}>Cookies Policy</span>
            

          <span className="policy-link"  onClick={helpCenterHandler}>Help Center</span>
          </div>
        </div>

        </div>
    </div>
  );
};

export default Footer2;