import React, { useEffect } from 'react';
import classes from './Client.module.css';
import Nn from "./NN.png";
import Rv from "./Sri-RV-school-PU-college.jpg";
import Etc from './etc.png';
import Tgrwa from "./tgrwalogo.png";
import Bv from "./logo.png";
import HT from './HT (5).png';
import Any from "./any.png";
import Assertive from './assertive.png';
import client from './Arindam.jpeg';
import Testimonial from './Testimonial';

const clients = [
  { name: 'NN Engineers', logo: Nn },
  { name: 'Sri RV School PU College', logo: Rv },
  { name: 'TGRWA', logo: Tgrwa },
  { name: 'Hello Toppers', logo: HT },
  { name: 'Bhakti Vedantist', logo: Bv },
  { name: 'Essential Classes', logo: Etc },
  { name: 'Any Quant UK', logo: Any },
  { name: 'Assertive Solutions', logo: Assertive },
];

const testimonials = [
  {
    clientName: 'Mira Peterson',
    jobTitle: 'SMM Manager',
    heading: 'Exceptional Service and Expertise',
    feedback: 'Working with DiracAI has been a game-changer for our business. Their team developed a highly customized software solution that perfectly fits our needs, and their attention to detail is unmatched. The web and mobile app development process was smooth, and the final product exceeded our expectations. We also appreciated their data analysis capabilities, which provided valuable insights for our decision-making. I highly recommend DiracAI for any technology solutions',
    image: client
  },
  {
    clientName: 'Ronald Dunkan',
    jobTitle: 'CEO Company',
    heading: "A Reliable Partner for Innovation",
    feedback: 'DiracAI’s expertise in software and app development is outstanding. They transformed our ideas into seamless, user-friendly platforms that have significantly improved our operations. The team is responsive, professional, and committed to delivering high-quality solutions. Their data analysis service helped us understand market trends and make more informed business decisions. We couldn’t ask for a better technology partner',
    image: client
  },
];

function ClientPage() {
  return (
    <div className={classes.clientPage}>
        <div className={classes.Heading}>Our Clients</div>
      <div className={classes.clientLogos}>
        <div className={classes.clientLogosContainer}>
          {clients.map((client, index) => (
            <div key={index} className={classes.clientItem}>
              <img src={client.logo} alt={client.name} className={classes.clientLogo} />
              <p className={classes.clientName}>{client.name}</p>
            </div>
          ))}
        </div>
      </div>
{/* 
      <div className={classes.clientDescription}>
        <h2>What Clients Say About Us</h2>
        <p>DiracAI's clients and their results are the best proof that our methodologies work. But we’ve also received numerous awards and praise from the media and our peers.</p>
      </div> */}
<Testimonial />

{/* 
      <div className={classes.testimonials}>
        {testimonials.map((testimonial, index) => (
          <div className={classes.testimonialCard} key={index}>
            <img src={testimonial.image} alt={`${testimonial.clientName} profile`} className={classes.profileImage} />
            <div className={classes.testimonialContent}>
              <h3>{testimonial.clientName}</h3>
              <p className={classes.jobTitle}>{testimonial.jobTitle}</p>
              <p className={classes.heading}>{testimonial.heading}</p>
              <p className={classes.feedback}>"{testimonial.feedback}"</p>
            </div>
          </div>
        ))}
      </div> */}
    </div>
  );
}

export default ClientPage;
