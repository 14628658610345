import classes from "./TeamBlock.module.css";
import bookAppointment from "./bookAppointment.jpg";
import { FaFacebookF } from "react-icons/fa";

import Reeta from "./Reeta.jpeg";
import Alok from "./Alok.png";
import Sanjit from "./Sanjit.png";
import Jacky from "./Jacky.jpeg";
import Kiran from "./KIran.jpg";
import Arpita from "./Arpita.png";
import Souvik from "./Souvik.png";
import Akshay from "./Akshay.jpeg";
import Bibhu from "./Bibhu.jpeg";
import Ipsit from "./Ipsit.jpeg";
import Rasmita from "./Rasmita.jpeg";
import Ateeb from "./Ateeb.png";
import Radha from "./Radha1.jpeg";
import Shradha from "./Shardha.jpeg";
import vibhav from "./Vibhav.jpeg";

import Reshwanth from "./reshwant.jpeg";

import OneTeamMember from "./OneTeamMember";

import { AiFillInstagram } from "react-icons/ai";
import { BsTwitter } from "react-icons/bs";


import Bibhuti from './Bibhuti.jpeg';

import Chinmay from './ChinmayR.jpg';



import SagarPatro from "./Sagar1.jpeg";

import Samarendra from "./Samarendra.jpg";

import Trushank from "./Trushank.jpeg";
import Soumen from "./Soumen.jpeg";
import debansh from './Debansh.png';
import Smutika from './smutika.png';

import SmrutiMadam from './SmrutiMadam.jpeg';


function TeamBlock() {
  return (
    <div className={classes.TeamBlockParent}>
      <div className={classes.parentOurTeam}>
        <div className={classes.ourTeam}>
          <div className={classes.ourTeamTitle}>Our Team</div>
          <div className={classes.ourTeamSubTitle}> Meet Our Team</div>
        </div>

        <div className={classes.allTeamMemberdetail}>

          <OneTeamMember
            name="Bibhuprasad Mahakud"
            role="Founder & CEO"
            status
            image={Bibhu}
            id="ID- 10001"
          />

          <OneTeamMember
            name="Ipsit Panda"
            role="Founder & CTO"
            image={Ipsit}
            id="ID-10002"
          />

<OneTeamMember
            name="Chinmay Padhi"
            role="Investor & COO"
            image={Chinmay}
            id="ID-10013"
          />

          <OneTeamMember name="Rasmita Sahoo" role="Advisor" image={Rasmita} />


          <OneTeamMember
            name="Debansh Das Sharma "
            role="Chief Strategic Adviser"
            image={debansh}
            id="ID-10006"
          />


           

	  <OneTeamMember
            name="Smruti Dash"
            role="Director"
            image={SmrutiMadam}
            id="ID-10016"
          />





          <OneTeamMember
            name="Sagar "
            role="HR & Web-Designer"
            image={SagarPatro}
            id="ID-10004"
          />

          <OneTeamMember
            name="Ateeb "
            role="Android Developer"
            image={Ateeb}
            id="ID-10005"
          />

          <OneTeamMember
            name="Rachuti "
            role="Web Developer"
            image={Reshwanth}
            id="ID-10007"
          />
          <OneTeamMember
            name="Trushank "
            role="Web Developer"
            image={Trushank}
            id="ID-10008"
          />


          <OneTeamMember
            name="Vibhav "
            role="Android Developer"
            image={vibhav}
            id="ID-10010"
          />

          <OneTeamMember
            name="Shradha "
            role="Frontend Developer"
            image={Shradha}
            id="ID-10012"
          />
           <OneTeamMember
            name="Smutikamayee "
            role="Sales Development Professional"
            image={Smutika}
            id="ID-10014"
          />


          <OneTeamMember
            name="Soumen Halder"
            role="Data-scientist"
            image={Soumen}
            id="ID-10015"
          />


          <OneTeamMember
            name="Samarendra "
            role="Data Scientist/Back-End Developer"
            image={Samarendra}
          />



          <OneTeamMember
            name="Reeta "
            role="Data Scientist"
            image={Reeta}
          />


           <OneTeamMember
            name="Radharani "
            role="Backend Developer"
            image={Radha}
            id="ID-10009"
            status = "(Ex-Employee)"
          />



          <OneTeamMember
            name="Kiran"
            role="Software Developer"
            image={Kiran}
	    status="(Ex-Employee)"
          />


          <OneTeamMember
            name="Souvik "
            role="Data-Scientist"
            status="(Ex-Employee)"
            image={Souvik}
          />







        </div>

	  {/*
        <div className={classes.ourTeamTitle1}>Ex-Employees</div>
        <div className={classes.exemployees}>
  <OneTeamMember
            name="Akshay Bhasme "
            role="Designer"
            status="(Ex-Employee)"
            image={Akshay}
          />

          <OneTeamMember
            name="Arpita Sahoo "
            role="Data Science Intern"
            status="(Ex-Employee)"
            image={Arpita}
          />

          <OneTeamMember
            name="Alok Kumar Sahoo "
            role="Digital Marketer"
            status="(Ex-Employee)"
            image={Alok}
          />

          <OneTeamMember
            name="Sanjit Mohapatra "
            role="Digital Marketer"
            status="(Ex-Employee)"
            image={Sanjit}
          />

         


        </div>
	*/}
      </div>
    </div>
  );
}

export default TeamBlock;
