import React, { useEffect } from "react";

const PDFViewer = ({ imageUrls }) => {
  // Disable right-click and key events
  useEffect(() => {
    const disableRightClick = (e) => e.preventDefault();
    const disableKeys = (e) => {
      if (e.key === "PrintScreen" || e.ctrlKey || e.metaKey) {
        e.preventDefault();
      }
    };

    document.addEventListener("contextmenu", disableRightClick);
    document.addEventListener("keydown", disableKeys);

    return () => {
      document.removeEventListener("contextmenu", disableRightClick);
      document.removeEventListener("keydown", disableKeys);
    };
  }, []);

  return (
    <div style={{ position: "relative" }}>
      {imageUrls.map((url, index) => (
        <img
          key={index}
          src={url}
          alt={`Page ${index + 1}`}
          style={{
            userSelect: "none",
            pointerEvents: "none",
            position: "relative",
          }}
        />
      ))}
      {/* Add a watermark overlay */}
      <div
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          zIndex: 10,
          background: "rgba(255, 255, 255, 0.1)",
          pointerEvents: "none",
        }}
      >
        <p
          style={{
            textAlign: "center",
            color: "rgba(0, 0, 0, 0.5)",
            fontSize: "2rem",
            transform: "rotate(-45deg)",
          }}
        >
          Confidential
        </p>
      </div>
    </div>
  );
};

export default PDFViewer;

